$(document).ready(function () {
  $("#menu-box").click(function () {
    $("body").toggleClass("body--hidden");
    $("#header").toggleClass("header--open");
  });

  $("#menu-product").mouseover(function () {
    $(".nav-service").removeClass("nav-service--active");
    $(".nav-product").addClass("nav-product--active");
  });
  $(".header").mouseleave(function () {
    $(".nav-product").removeClass("nav-product--active");
  });

  $("#menu-service").mouseover(function () {
    $(".nav-product").removeClass("nav-product--active");
    $(".nav-service").addClass("nav-service--active");
  });
  $(".header").mouseleave(function () {
    $(".nav-service").removeClass("nav-service--active");
  });

  $("#menu--product").click(function () {
    $("#header").addClass("header--product");
    $("#menu-box").css("display", "none");
    $("#menu-box--back").css("display", "block");
  });

  $("#menu--service").click(function () {
    $("#header").addClass("header--service");
    $("#menu-box").css("display", "none");
    $("#menu-box--back").css("display", "block");
  });

  $("#menu-box--back").click(function () {
    $("#header").removeClass("header--product");
    $("#header").removeClass("header--service");
    $("#menu-box").css("display", "block");
    $("#menu-box--back").css("display", "none");
  });
});

$(document).ready(function () {
  $(".modal").click(function () {
    $("body").toggleClass("body--hidden");
    $("#modal").toggleClass("overlay--open");
  });

  $("#modal--close").click(function () {
    $("#modal").removeClass("overlay--open");
    $("body").removeClass("body--hidden");
  });
});

var footerSelect = $(".footer__select");
var footerOnClick = function (event) {
  this.classList.toggle("footer__select--active");
};

for (var i = 0; i < footerSelect.length; i++) {
  footerSelect[i].onclick = footerOnClick;
}

var navSelect = $(".nav__select");
var navOnClick = function (event) {
  this.classList.toggle("nav__select--active");
};

for (var i = 0; i < navSelect.length; i++) {
  navSelect[i].onclick = navOnClick;
}

(function () {
  var el = document.getElementById("container-move");
  if (!el) return;
  var rect = el.getBoundingClientRect();
  var mouse = { x: 0, y: 0, moved: false };

  $("#container-move").mousemove(function (e) {
    mouse.moved = true;
    mouse.x = e.clientX - rect.left;
    mouse.y = e.clientY - rect.top;
  });

  // Ticker event will be called on every frame
  TweenLite.ticker.addEventListener("tick", function () {
    if (mouse.moved) {
      parallaxIt(".img-move", -40);
    }
    mouse.moved = false;
  });

  function parallaxIt(target, movement) {
    TweenMax.to(target, 0.3, {
      x: ((mouse.x - rect.width / 2) / rect.width) * movement,
      y: ((mouse.y - rect.height / 2) / rect.height) * movement,
    });
  }

  $(window).on("resize scroll", function () {
    rect = el.getBoundingClientRect();
  });
})();
