(function () {
  var options = {
    rootMargin: "0%",
    threshold: 0.3,
    selector: ".show-on-scroll",
    animateClassName: "shown",
  };

  var elements = [];
  var intersectionObserver = null;

  function clearAnimation(element) {
    element.classList.remove(options.animateClassName);
  }

  function animate(entry) {
    entry.target.classList.add(options.animateClassName);
  }

  function reverse(entry) {
    clearAnimation(entry.target);
  }

  function isAnimated(element) {
    return element.classList.contains(options.animateClassName);
  }

  function clearObserver() {
    intersectionObserver.disconnect();
    intersectionObserver = null;
  }

  function onIntersection(entries, observer) {
    entries.forEach(function (entry) {
      if (
        entry.intersectionRatio >= options.threshold &&
        entry.isIntersecting
      ) {
        animate(entry);
        observer.unobserve(entry.target);
      }
    });
  }

  function enable() {
    intersectionObserver = new IntersectionObserver(onIntersection, {
      rootMargin: options.rootMargin,
      threshold: options.threshold,
    });

    elements = [].filter.call(
      document.querySelectorAll(options.selector),
      function (element) {
        return !isAnimated(element, options.animateClassName);
      }
    );

    elements.forEach(function (element) {
      intersectionObserver.observe(element);
    });
  }

  if (!window || !window.IntersectionObserver) {
    return;
  }

  enable();
})();
